import React from 'react';
import { DatePicker as DatePickerAntd } from 'antd';
import { Box, useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { useLocale, useInput, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';

import localeEn from 'antd/es/date-picker/locale/en_US';
import localeZhCn from 'antd/es/date-picker/locale/zh_CN';
import localeTh from 'antd/es/date-picker/locale/th_TH';
import { DATE_DISPLAY_FORMAT, SERVER_DATETIME_FORMAT } from '../../../../constant';

const getAntDesignLocale = locale => {
  switch (locale) {
    case 'cn':
      return localeZhCn;
    case 'th':
      return localeTh;
    case 'en':
    default:
      return localeEn;
  }
};

const rootStyles = {
  marginTop: '8px',
  marginBottom: '4px',
};

const inputContainerStyles = ({
  error, muiTheme,
}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '40px',
  border: `1px solid ${error ? muiTheme.palette.error.main : '#0000003B'}`,
  borderRadius: '4px',
  background: '#FFFFFF',
  marginTop: '8px',
  position: 'relative',
});

const labelStyles = {
  position: 'absolute',
  top: -7,
  left: 9,
  padding: '0 6px',
  maxWidth: '1000px',
  background: '#FFFFFF',
  color: alpha('#000000', 0.6),
  fontSize: '12px',
  fontFamily: 'Normal',
  fontWeight: 400,
  lineHeight: 1,
};

const DatePickerInput = props => {
  const {
    label, source, errorMessage, ...otherProps
  } = props;

  const translate = useTranslate();

  const locale = useLocale();
  const antDesignLocale = getAntDesignLocale(locale);

  const {
    isRequired,
    input,
    meta: {
      error, submitError, touched,
    },
  } = useInput(props);
  const { values: formValues } = useFormState();

  const muiTheme = useTheme();

  const onChange = value => {
    input.onChange(value.startOf('second').format(SERVER_DATETIME_FORMAT));
  };

  const currentTime = _.get(formValues, source);
  const errorText = touched ? error || submitError || errorMessage : '';

  return (
    <Box
      style={rootStyles}
      className="date-picker-input"
    >
      <Box
        style={inputContainerStyles({
          error: errorText,
          muiTheme,
        })}
      >
        <Box
          component="label"
          style={labelStyles}
        >
          {label}
          {isRequired ? ' *' : ''}
        </Box>

        <DatePickerAntd
          showTime
          {...input}
          allowClear={false}
          bordered={false}
          suffixIcon={null}
          value={currentTime ? moment(currentTime).startOf('second') : undefined}
          onChange={onChange}
          format={DATE_DISPLAY_FORMAT}
          locale={antDesignLocale}
          {...otherProps}
        />
      </Box>
      <Box
        style={{
          margin: '4px 14px 0px',
          color: muiTheme.palette.error.main,
          minHeight: '20px',
          fontSize: '0.75rem',
        }}
      >
        {errorText ? translate(errorText) : ''}
      </Box>
    </Box>
  );
};

DatePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

DatePickerInput.defaultProps = {
  errorMessage: undefined,
};

export default DatePickerInput;
